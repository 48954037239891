import { ProgressBar } from '@enterprise-ui/canvas-ui-react'
import { Unauthorized } from 'components/Unauthorized/Unauthorized'
import { lazy, Suspense } from 'react'

const MerchVendorShipmentManagement = lazy(
  () => import('components/Routes/MerchVendorShipmentManagement'),
)
const ConsolidatorShipmentManagement = lazy(
  () => import('components/Routes/ConsolidatorShipmentManagement'),
)
const ReverseLogisticsShipmentManagement = lazy(
  () => import('components/Routes/ReverseLogisticsShipmentManagement'),
)

export const ShipmentManagementWrapper = (props: any) => {
  const { userInfo, internalUserTypeSelectValue } = props
  const {
    isInternal,
    isSet,
    isMerchVendorUser,
    isConsolidatorUser,
    isReverseLogisticsUser,
  } = userInfo

  if (isSet) {
    if (
      isMerchVendorUser ||
      (isInternal && internalUserTypeSelectValue === 'MERCH_VENDOR')
    ) {
      return (
        <Suspense fallback={<ProgressBar indeterminate />}>
          <MerchVendorShipmentManagement />
        </Suspense>
      )
    } else if (
      isConsolidatorUser ||
      (isInternal && internalUserTypeSelectValue === 'CONSOLIDATOR')
    ) {
      return (
        <Suspense fallback={<ProgressBar indeterminate />}>
          <ConsolidatorShipmentManagement />
        </Suspense>
      )
    } else if (
      isReverseLogisticsUser ||
      (isInternal && internalUserTypeSelectValue === 'REVERSE_LOGISTICS')
    ) {
      return (
        <Suspense fallback={<ProgressBar indeterminate />}>
          <ReverseLogisticsShipmentManagement />
        </Suspense>
      )
    } else {
      return <Unauthorized />
    }
  } else {
    return <ProgressBar />
  }
}
