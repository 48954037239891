import { get, uniq } from 'lodash'
import apiConfig, { POL_ACCESS } from '../../config/apiConfig'
import mockVendorSession from '../../mocks/mockVendorSession.json'
import { getParam } from '../../common/utils'
import { VIEWS } from './constants'
import { Notifications } from 'common/types'

export const getUserInfo = (session: any) => {
  const sessionToBeUsed =
    getParam('mockVendor') === true ? mockVendorSession : session

  const allEntitlements = () => {
    return [
      ...apiConfig.entitlements.tgtInternalRead,
      ...apiConfig.entitlements.tgtInternalWrite,
      ...apiConfig.entitlements.polRead,
      ...apiConfig.entitlements.polWrite,
    ]
  }

  const isInternal = get(sessionToBeUsed, 'userInfo.memberOf', []).some(
    (item: string) =>
      uniq([
        ...apiConfig.entitlements.tgtInternalRead,
        ...apiConfig.entitlements.tgtInternalWrite,
      ]).includes(item),
  )

  const isExternal = get(sessionToBeUsed, 'userInfo.memberOf', []).some(
    (item: string) =>
      uniq([
        ...apiConfig.entitlements.polRead,
        ...apiConfig.entitlements.polWrite,
      ]).includes(item),
  )

  const isMerchVendorUser = sessionToBeUsed?.userInfo?.memberOf.includes(
    POL_ACCESS.MERCH_VENDOR,
  )
  const isConsolidatorUser = sessionToBeUsed?.userInfo?.memberOf.includes(
    POL_ACCESS.CONSOLIDATOR,
  )
  const isReverseLogisticsUser = sessionToBeUsed?.userInfo?.memberOf.includes(
    POL_ACCESS.REVERSE_LOGISTICS,
  )

  return {
    firstName: sessionToBeUsed?.userInfo?.firstName,
    lastName: sessionToBeUsed?.userInfo?.lastName,
    isSet: true,
    isExternal,
    isInternal,
    isMerchVendorUser,
    isConsolidatorUser,
    isReverseLogisticsUser,
    transportationProfile: {
      read: get(sessionToBeUsed, 'userInfo.memberOf', []).some((item: string) =>
        uniq(allEntitlements()).includes(item),
      ),
      write: get(sessionToBeUsed, 'userInfo.memberOf', []).some(
        (item: string) =>
          uniq([
            ...apiConfig.entitlements.tgtInternalWrite,
            ...apiConfig.entitlements.polWrite,
          ]).includes(item),
      ),
    },
    shipmentManagement: {
      read: get(sessionToBeUsed, 'userInfo.memberOf', []).some((item: string) =>
        uniq(allEntitlements()).includes(item),
      ),
      write: get(sessionToBeUsed, 'userInfo.memberOf', []).some(
        (item: string) =>
          uniq([
            ...apiConfig.entitlements.tgtInternalWrite,
            ...apiConfig.entitlements.polWrite,
          ]).includes(item),
      ),
    },
  }
}

export const isNewFeatureVersion = (
  currVersion: string | undefined,
  prevVersion: string | undefined | null,
) => {
  return (
    (currVersion &&
      prevVersion &&
      currVersion.split('.')[1] !== prevVersion.split('.')[1]) ||
    prevVersion === null
  )
}

export const notificationTypes = {
  PURCHASE_ORDER_NUMBER: 'PURCHASE_ORDER_NUMBER',
  TGT_BOOKING_ID: 'TGT_BOOKING_ID',
}

export const getNotificationUrl: (
  vendorIds: number[],
  notifications: Notifications,
) => string = (vendorIds, notifications) => {
  if (notifications?.identifiers?.length) {
    const vendors = vendorIds ? vendorIds.join(',') : ''
    const ids = notifications.identifiers.map((id) => id.entity_id).join(',')
    if (notifications.entity === notificationTypes.PURCHASE_ORDER_NUMBER) {
      return `${VIEWS.SHIPMENT_MANAGEMENT.path}?query={"purchaseOrders":[${ids}],"vendorIds":[${vendors}]}`
    }
    if (notifications.entity === notificationTypes.TGT_BOOKING_ID) {
      return `${VIEWS.SHIPMENT_MANAGEMENT.path}?query={"shipmentIds":["${ids}"],"vendorIds":[${vendors}]}`
    }
  }
  return ''
}
