import axios from 'axios'
import { getUiServicesEndpoint } from './utils'

import {
  ShipmentRowInterface,
  ShipmentModificationVariables,
} from '../common/types'
import dayjs from 'dayjs'
import { pickupDateChangeReasons } from 'constants/pickupDateChangeReason'

export const transformShipments = (shipments: Array<ShipmentRowInterface>) => {
  return {
    modification_requests: shipments.map((shipment: ShipmentRowInterface) => {
      const {
        appointmentNumber,
        bookingId,
        bookingVersion,
        bol,
        carton,
        commodityCode,
        loadId,
        origLocCode,
        pallet,
        pickupDate,
        pickupReasonCode,
        toBeCancelled,
        totalVol,
        totalWt,
        volUom,
        wtUom,
        vop,
        tloRefId,
      } = shipment

      if (toBeCancelled) {
        return {
          load_id: loadId,
          booking_modification_request: {
            id: bookingId,
            version: bookingVersion,
            change_reason: 'vendor change',
            action: 'CANCEL',
          },
        }
      }

      const hasPickupOverride =
        !!pickupReasonCode &&
        pickupReasonCode !==
          pickupDateChangeReasons.TGT_DRIVEN_PICKUP_DATE_OVERRIDE.label

      return {
        load_id: loadId,
        booking_modification_request: {
          action: 'MODIFY',
          id: bookingId,
          version: bookingVersion,
          modified_attributes: {
            origin_location: {
              code: origLocCode,
              vop,
            },
            truck_load_optimization_ref_id: tloRefId || null, // temporary fix until backend handles omitting this
            commodity_code: commodityCode,
            measures: {
              carton_quantity: carton,
              pallet: pallet,
              weight: {
                total: totalWt,
                unit_of_measure: wtUom,
              },
              volume: {
                total: totalVol,
                unit_of_measure: volUom,
              },
            },
            ...(hasPickupOverride && {
              pickup_override: {
                date: dayjs(pickupDate).format('YYYY-MM-DD'),
                change_reason: pickupReasonCode,
              },
            }),
            vendor_release: 'VENDOR_ACKNOWLEDGED',
            appointment_number: appointmentNumber,
            bill_of_lading: bol,
          },
        },
      }
    }),
  }
}

export const shipmentsLoadModificationsEndpoint = async ({
  shipments,
  uid,
}: ShipmentModificationVariables) => {
  const modifiedShipments = shipments.filter((shipment: any) => {
    return shipment?.loadId
  })

  const transformedShipments = transformShipments(modifiedShipments)

  if (uid <= 0) {
    return
  }
  if (modifiedShipments.length <= 0) {
    return
  }
  const { key, url } = await getUiServicesEndpoint()
  const endpoint = `${url}/v1/load_modifications?key=${key}`

  try {
    await axios({
      method: 'post',
      url: endpoint,
      data: transformedShipments,
    })
    return []
    // eslint-disable-next-line
  } catch (err: any) {
    return modifiedShipments
  }
}
