import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { getParam } from 'common/utils'

import { useGlobalContext } from 'context/GlobalContext'

const getFilterByLocationPathName = (pathname: string, filters: any) => {
  if (window.location.href.includes('locations')) {
    return filters.transportationProfileLocations || {}
  }

  if (window.location.href.includes('products')) {
    return filters.transportationProfileProducts || {}
  }

  if (window.location.href.includes('shipment-management')) {
    return filters.shipmentManagement
  }
}

const Queries = () => {
  const { globalState, globalDispatch } = useGlobalContext()
  const { filters } = globalState
  const isDebugMode = getParam('debug')
  const isMockVendorMode = getParam('mockVendor')
  const navigate = useNavigate()

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const query = searchParams.get('query') || '{}'

    globalDispatch({
      type: 'SET_INITIAL_FILTER_QUERIES',
      payload: {
        shipmentManagement: JSON.parse(query),
      },
    })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const path = window.location.pathname.includes('featureupvote')
      ? window.location.pathname
      : `${window.location.pathname}?query=${JSON.stringify({
          ...getFilterByLocationPathName(window.location.pathname, filters),
          ...(isDebugMode && { debug: true }),
          ...(isMockVendorMode && { mockVendor: true }),
        })}`
    navigate(path)
    // eslint-disable-next-line
  }, [filters, window.location.pathname])

  return <></>
}

export default Queries
