import axios from 'axios'
import { logEvent, LogLevel } from '@praxis/component-logging'
import { getUiServicesEndpoint } from './utils'
import { VendorId } from 'common/types'

export type FirstMileTransitTimeResponse = {
  origin: string
  vendorOrderSpec: string
  destination_id: string
  department_id: string
  preferredMode: string
  shortest_transit_time: number
  nonConsol: [TransitTime]
  consol: [ConsolTransitTime]
}

export type TransitTime = {
  mode: string
  transitTime: number
}

export type ConsolTransitTime = {
  scac: string
  processingTime: number
  dwellTime: number
  inbound: TransitTime
  outbound: TransitTime
}

export const getShortestTransitTime: (
  vendorId: VendorId,
  vop: number,
  department: string | number,
  destination: string,
) => Promise<{ data: FirstMileTransitTimeResponse } | Error> = async (
  vendorId,
  vop,
  department,
  destination,
) => {
  const { key, url } = await getUiServicesEndpoint()
  let endpoint = `${url}/v1/shipment/transit_time?vendor_id=${[
    vendorId,
  ]}&vop=${vop}&department=${department}&destination=${destination}&key=${key}`

  try {
    const response = await axios({
      method: 'get',
      url: endpoint,
    })
    return response.data || {}
  } catch (err: any) {
    const message = `Failed to load shortest transit time for vendor ${vendorId}, vop ${vop}, destination ${destination}, department ${department}`

    logEvent(
      {
        message: JSON.stringify({
          endpoint: endpoint,
          error: err?.response?.data,
          message: message,
          type: 'REQUEST_FAILURE',
          user: localStorage?.userInfo,
        }),
        url: window.location.href,
      },
      { level: LogLevel.Error },
    )

    return new Error(message)
  }
}
