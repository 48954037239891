import { Heading } from '@enterprise-ui/canvas-ui-react'
import { useLocation } from 'react-router-dom'
import { VIEWS } from './constants'

export const LocationHeader = () => {
  const location = useLocation()
  let viewTitle = ''
  if (location.pathname.includes(VIEWS.SHIPMENT_MANAGEMENT.path)) {
    viewTitle = VIEWS.SHIPMENT_MANAGEMENT.title
  } else if (location.pathname.includes(VIEWS.TRANSPORTATION_PROFILE.path)) {
    viewTitle = VIEWS.TRANSPORTATION_PROFILE.title
  } else if (location.pathname.includes(VIEWS.FAQ.path)) {
    viewTitle = VIEWS.FAQ.title
  } else if (location.pathname.includes(VIEWS.FEATURE_UPVOTE.path)) {
    viewTitle = VIEWS.FEATURE_UPVOTE.title
  }

  return (
    <Heading className="hc-pa-none hc-ws-no-wrap" size={5}>
      {viewTitle}
    </Heading>
  )
}
